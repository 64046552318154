.full-size {
    width: 100%;
}

.image-thumbnail {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 1rem;
    border: 4px solid #fefefe;
    line-height: 0;
}

.site-logo {
    width: 140px;
}

.post-image-lead {
    margin-bottom: 2rem;
}
