// Media Queries
@mixin respond-to($breakpoint) {
   @if $breakpoint == "desktop" {
     @media (min-width: 70em) {
         @content;
     }
  }
   @else if $breakpoint == "laptop" {
     @media (min-width: 64em) {
         @content;
     }
  }
    @else if $breakpoint == "tablet" {
     @media (min-width: 50em) {
         @content;
     }
  }
   @else if $breakpoint == "phablet" {
     @media (min-width: 37.5em)  {
         @content;
     }
  }
  @else if $breakpoint == "mobile-only" {
     @media (max-width: 37.5em)  {
         @content;
     }

  }
}

// Colors
$--black: #000;
$--white: #fff;
$--gray-100: #1a1a1a;
$--gray-90: #313131;
$--gray-80: #484848;
$--gray-70: #5f5f5f;
$--gray-60: #767676;
$--gray-50: #8d8d8d;
$--gray-40: #a3a3a3;
$--gray-30: #bababa;
$--gray-20: #d1d1d1;
$--gray-10: #e8e8e8;
$--gray-05: #f4f4f4;
$--gray-02: #fafafa;
$--red-100: #ce1a2b;
$--red-90: #d33140;
$--red-20: #f5d1d5;
$--red-10: #fae8ea;
$--red-140: #7c101a;
$--blue-100: #007ab8;
$--blue-90: #1a87bf;
$--blue-20: #cce4f1;
$--blue-10: #e6f2f8;
$--blue-140: #00496e;
$--green-100: #45b931;
$--green-90: #58c046;
$--green-20: #daf1d6;
$--green-10: #ecf8ea;
$--green-140: #296f1d;
$--orange-100: #f07323;
$--orange-90: #f28139;
$--orange-20: #fce3d3;
$--orange-10: #fef1e9;
$--orange-140: #904515;
$--teal-100: #00a8cb;
$--info: #007ab8;
$--primary: #007ab8;
$--secondary: #a3a3a3;
$--success: #45b931;
$--warning: #f07323;
$--alert: #ce1a2b;
$--error: #ce1a2b;

$--salmon: #ffd0d5;
$--sunflower: #ffbc4b;
$--disco-blue: #3F5EFB;
$--mimosa-red: #d53369;

$--bloody-mimosa: linear-gradient(90deg, #d53369 0%, #daae51 100%);
$--disco-club: linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%);
$--burning-sky: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
