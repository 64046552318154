.site-footer {
    background-color: $--gray-90;
    color: $--gray-40;

    #footerInfoLegal {
        border-top: 1px solid;
        border-color: $--gray-80;
    }

    .site-footer_navigation {
        a {
            color: $--gray-40;
            text-decoration: none;
        }
    }

    .adr span {
        display: block;
    }
}
