.podcast-episodes-grid {
    padding: 4rem 0;
    border-bottom: 1px solid $--gray-10;
}

.podcast-episode-thumbnail {
    width: 100%;
}

.podcast-episode-title {
    font-size: 1.5rem;
    line-height: 1.2;

    a {
        color: $--black;
        transition: all 0.2s ease;

        &:hover {
            color: $--disco-blue;
        }
    }
}

.podcast-episode-summary {
    margin-bottom: 1rem;
}

.podcast-episode-metadata {
    display: inline-block;
    margin: 0.5rem 0;
    padding: 0.3rem;
    background: $--black;
    color: $--white;
    text-transform: uppercase;
    font-size: 0.8rem;
}
