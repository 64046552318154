.button {
    border: 1px solid transparent;
    border-radius: 100px;
    transition: all 0.2s ease;
    text-decoration: none;

    &:hover {
        background-color: lighten($--black, 20);
    }

    &.primary {
        background-color: $--black;
        color: $--white;

        &:hover {
            border: 1px solid $--black;
            background-color: transparent;
            color: $--black;
        }
    }
}

.text-link {
    margin-top: 1.5rem;
    text-transform: uppercase;
    font-weight: bold;
    transition: all 0.2s ease;
}

[type="submit"] {
    &.button {
        border: 1px solid $--black;
        transition: all 0.2s ease;
        background-color: $--black;
        color: $--white;

        &:hover {
            background-color: lighten($--black, 20);
        }
    }
}
