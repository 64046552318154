.dropdown.menu>li.is-active>a {
    color: $--mimosa-red;
}

.dropdown.menu.medium-horizontal>li.is-dropdown-submenu-parent>a {
    &:hover {
        color: $--mimosa-red;
    }
}
.dropdown.menu.medium-horizontal>li.is-dropdown-submenu-parent>a::after,
.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle)>a::after {
    border-color: $--mimosa-red transparent;
}

ul.submenu {

    &.is-dropdown-submenu {
        border-color: $--mimosa-red;
        border-top: 0;
    }

    li {
        font-size: 1rem;
    }
}
