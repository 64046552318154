.page-header {
    border-bottom: 1px solid $--gray-10;
    margin-top: 100px;

    &.no-border {
        border: 0;
        margin-top: 0;
        padding-top: 100px;
    }

    &.home-banner {
        margin-top: 0;
        background: $--disco-club;
        color: $--white;

        .flex-container {
            height: 80vh;
        }
    }

    &.about-banner {
        margin-top: 0;
        background: $--disco-club;
        background-image: url(/assets/img/backgrounds/bg-andy-full.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: $--white;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);

        .flex-container {
            height: 60vh;
        }
    }

    &.offer-banner {
        margin-top: 0;
        background: $--disco-club;
        background-image: url(/assets/img/backgrounds/bg-swimmer.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: $--white;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);

        .flex-container {
            height: 60vh;
        }
    }

    &.book-banner {
        margin-top: 0;
        padding-top: 100px;
        border: 0;
    }
}
