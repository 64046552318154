.section-standard {
    border-bottom: 1px solid $--gray-40;
}

.section-standard--highlight {
    @extend .section-standard;
    color: $--gray-70;
}

.section-promo {
    @extend .section-standard;
    padding: 2rem 0;
}

.section-standard--newsletter {
    background: $--bloody-mimosa;
    color: $--white;

    label {
        color: $--white;
    }
}

.has-cover {
    background-size: cover;
}

.section-standard--credentials {
    margin: 4rem 0;
}

.section-standard--about {
    margin-bottom: 100px;
}

.section-booking--details {
    h1,
    h2 {
        line-height: 1;
        margin-bottom: 1.8rem;
    }
}

.section-standard--packages {
    margin-top: 4rem;

    .packages-container {
        padding: 4rem 0;

        .package-item {
            padding-bottom: 2rem;
            border-bottom: 4px solid $--mimosa-red;
        }

        .package-item-icon {
            margin-bottom: 2rem;
        }

        .package-item-title {
            text-align: center;
        }

        .package-item-summary {
            font-size: 1.2rem;
        }

        .package-item-description {
            font-size: 1rem;
        }
        .package-item-price {
            font-display: 1rem;
            color: $--gray-50;
        }
    }
}

.booking-description {
    margin-top: 4rem;
}

.bg-gray-10 {
    background: $--gray-10;
}

.bg-disco-blue {
    background: $--disco-blue;
    color: $--white;
}

.bg-sunflower {
    background: $--sunflower;
}

.bg-salmon {
    background: $--salmon;
}

.bg-mimosa-red {
    background: $--mimosa-red;
    color: $--white;
}

.bg-gray-10 {
    background: $--gray-10;
}

.section-standard--testimonials {
    background: $--disco-blue;
    color: $--white;

    blockquote {
        color: $--white;

        p {
            color: $--white;
        }
    }
}
//
// .calendly-inline-widget {
//     min-width:320px;
//     height:700px;
// }
