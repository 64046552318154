.site-header {
    background-color: $--white;
    font-weight: 900;
    // text-align: center;

    .top-bar {
        padding: 0;
        background-color: $--white;

        ul {
            background-color: $--white;
        }

        .nav-bar {
            text-transform: uppercase;
        }

        .menu .menu-text {
            padding: 0;
        }

        a {
            color: $--black;

            &:hover {
                color: $--gray-80;
            }
        }
    }
}
