//Margin setups
.m-0 {
    margin: 0;
}

.m-1 {
    margin: .5rem;
}

.m-2 {
    margin: 1rem;
}

.m-3 {
    margin: 1.5rem;
}
.m-4 {
    margin: 2rem;
}

.mt-0 {
    margin-top: 0;
}

.mt-1 {
    margin-top: .5rem;
}

.mt-2 {
    margin-top: 1rem;
}

.mt-3 {
    margin-top: 1.5rem;
}
.mt-4 {
    margin-top: 2rem;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: .5rem;
}

.mb-2 {
    margin-bottom: 1rem;
}

.mb-3 {
    margin-bottom: 1.5rem;
}
.mb-4 {
    margin-bottom: 2rem;
}

.mx-0 {
    margin-left: 0;
    margin-right: 0;
}

.mx-1 {
    margin-left: .5rem;
    margin-right: .5rem;
}

.mx-2 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.mx-3 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}
.mx-4 {
    margin-left: 2rem;
    margin-right: 2rem;
}

.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.my-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.my-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.my-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.my-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

//Padding setups
.p-0 {
    padding: 0;
}

.p-1 {
    padding: .5rem;
}

.p-2 {
    padding: 1rem;
}

.p-3 {
    padding: 1.5rem;
}
.p-4 {
    padding: 2rem;
}

.pt-0 {
    padding-top: 0;
}

.pt-1 {
    padding-top: .5rem;
}

.pt-2 {
    padding-top: 1rem;
}

.pt-3 {
    padding-top: 1.5rem;
}
.pt-4 {
    padding-top: 2rem;
}

.pb-0 {
    padding-bottom: 0;
}

.pb-1 {
    padding-bottom: .5rem;
}

.pb-2 {
    padding-bottom: 1rem;
}

.pb-3 {
    padding-bottom: 1.5rem;
}
.pb-4 {
    padding-bottom: 2rem;
}

.px-0 {
    padding-left: 0;
    padding-right: 0;
}

.px-1 {
    padding-left: .5rem;
    padding-right: .5rem;
}

.px-2 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.px-3 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.px-4 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.py-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.py-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.py-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.py-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.vh-60 {
    height: 60vh;
}

.vh-80 {
    height: 80vh;
}

.vh-100 {
    height: 100vh;
}
