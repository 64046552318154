[type=text] {
    border: none;
    max-width: 20rem;
}

input {
    color: $--black;
}

// embed for Calendly
.calendly-inline-widget {
    width: 80vw;
    max-width: 800px;
    height: 70vh;

    iframe {}
}
