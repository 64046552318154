.section-promo--bar {
    background-color: $--black;
    padding: 0 1.875rem;

    .section-promo--bar_text {
        color: $--white;
        text-transform: uppercase;
        letter-spacing: .15rem;

        a {
            color: $--orange-100;
            text-decoration: none;

            &:hover {
                color: $--red-90;
            }
        }
    }
}
