// Fonts
// Fira sans for headings and large black type
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700;900&display=swap');

$body-font: 'Fira Sans', ui-sans, sans-serif;

body {
    font-family: $body-font;
    font-weight: 400;
}
h1,
h2,
h3 {
    font-weight: 900;
}

h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    line-height: 1.2;
}

h3 {
    margin-top: 1.5rem;
}
//sizes
p,
li {
    font-size: clamp(1rem, 2.5vw, 1.313rem);
    line-height: 1.2;
}

p.lead {
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
}

.post-subtitle {
    font-weight: 400;
    margin-top: 0;
}

ul.list-inline {
    li {
        display: inline-block;
    }

}

.social-list {
    li {
        margin-right: 1rem;

        a {
            font-size: 2rem;
            padding: 0.5rem;
            color: $--white;

            &:hover {
                color: darken($--white, 20%);
            }
        }
    }
}

.list-padding-y {
    li {
        margin-bottom: 1rem;
    }
}

.text-xs {
    font-size: .75rem;
}

.text-sm {
    font-size: .875rem;
}

.text-base,
.text-md {
    font-size: 1rem;
}

.text-lg {
    font-size: 1.125rem;
}

.text-xl {
    font-size: clamp(2rem, 4vw, 3.6rem);
    line-height: 1.1;
}

.text-2xl {
    font-size: clamp(2.2rem, 6vw, 4.8rem);
    line-height: 1;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.leading-none {
    line-height: 1;
}

.leading-tight {
    line-height: 1.25;
}

.leading-normal {
    line-height: 1.5;
}

.strikethrough {
    text-decoration: line-through;
}

//Colours
a {
    color: $--disco-blue;
    text-decoration: underline;
    transition: all 0.2s ease;

    &:hover {
        color: lighten($--disco-blue, 20%);
        text-decoration: none;
    }
}

.c-mimosa-red {
    color: $--mimosa-red;
}

.c-disco-blue {
    color: $--disco-blue;
}
